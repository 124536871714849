body
  border-top: 4px solid #7ed6d4

body #header
  margin-bottom: 18px

#logo
  margin-top: 80px
  margin-bottom: 0

#search-form
  background: none
  border: 3px solid #f1f1f1
  margin-top 0

body input#searchinput
  color: #92dcda
  text-shadow: none
  background-color: #fff
  font-style: normal
