body
  #breadcrumbs
    margin-top: 0

  #sidebar .widget
    background-color: transparent
    -webkit-box-shadow: none
    box-shadow: none
    border: none

    .widgettitle
      font-family: FuturaLTDCondensed, sans-serif;

    .recentcomments li span, .recentcomments li a
      font-family: Candy

    #tabbed-area li a, .widgettitle
      font-family: FuturaLTDCondensed, sans-serif;
      color: $turquoise
      font-size 26px

  #tabbed span.title a
    font-size 18px
    color: $dark-grey

  #tabbed-area
    background-color: transparent

  #tabbed-area li.ui-state-active a
    background-color: transparent

  .post-text h1.title, .blog-title a
    color: $candy
    font-family: Candy
    text-transform: none

  .post-text
    font-family $ff-titles-soft
    font-size 25px

    p
      line-height 1em

    .hr
      margin 15px 0 20px 0

    blockquote
      font-family $ff-italic
      font-size 20px
      color $dark-grey
      margin: 1.5em 0
      padding 0
      background: none
      font-style: normal
      border: none
      p
        line-height 1.2em

    ul
      color: $candy
      list-style: none
      padding-left 0
      margin-top 0
      margin-left 0
      line-height 1.2em
      font-family $ff-italic
      margin-bottom 30px
      font-size 18px

  h3#comments, h3#reply-title span
    color: #7ed6d4

  .excerpt
    font-family: AmTypEFMedIta, sans-serif
    color: #515054
    font-size: 15px

  #content-bottom-bg
    border-bottom: none
    background: none

  #container .infotitle
    font-family: FuturaLTDCondensed, sans-serif;
    font-size: 26px;

.info-panel
  text-align right
  font-family $ff-titles-soft, sans-serif
  font-size 20px
  margin-bottom 60px

.info-panel .category
  display inline-block
  padding 0 10px
  background-color $candy
  line-height 30px
  margin-bottom 10px
  color #beb4ab

  a
    color white
    text-decoration none
    font-size 20px
    text-transform uppercase
