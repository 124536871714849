@font-face
  font-family: NeutraDisp-Bold
  src: url(./fonts/NeutraDisp-Bold.woff) format('woff')

@font-face
  font-family: NeutraDisp-Medium
  src: url(./fonts/NeutraDisp-Medium.woff) format('woff')

@font-face
  font-family: AmTypEFMedIta
  src: url(./fonts/AmTypEFMedIta.woff) format('woff')

@font-face
  font-family: Candy
  src: url(./fonts/Candy.woff) format('woff')

@font-face
  font-family: FuturaLTDCondensed
  src: url(./fonts/FuturaLTDCondensed.woff) format('woff')
  
