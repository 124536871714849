body #footer
  background: #7ed6d4
  border: none
  padding-top 100px

body #footer-wrapper
  border: none
  background: none

.copyright
  color: #fff

body #footer
  margin-bottom 8px
  border-bottom: 7px solid #ffc758

  #footer-content, .container
    padding: 0px 0 0px 3%
