body
  #boxes
    // margin: 0 3%
    h2.title a
      font-family Candy
      font-size 22px
      color: $candy
      text-transform: none
      padding-bottom 18px

    div.category
      background-color: $candy
      font-size: 15px

    div.entry-content
      margin-top 16px

  &.cufon-disabled
    div.category a
      font-size: 16px
      font-family: NeutraDisp-Medium, sans-serif
      text-shadow: none
    span.month
      font-family: $ff-sans-serif
      text-shadow: none
      top: 45px
