body
  ul.nav
    float none
    color white
    background-image url('./images/nav-pattern.gif')
    margin-top 8px
    margin-bottom 18px
    margin-left -3%
    padding-left 3%
    padding-top 13px

    ul
      background-color white

    > li.sf-ul.sfHover > a
      background-color white

    a
      color #beb4ab

    a:hover, > li.current-menu-item > a
      color #7ed6d4
      text-shadow none

  &.cufon-disabled ul.nav a
    font-family NeutraDisp-Bold, sans-serif
    font-size 25px

  #sidebar .info-panel
    width 100%

    .tags li
      float: right
