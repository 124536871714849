// Pagination
body
  #controllers span#left-arrow
    background url(images/gray/left-arrow.png) no-repeat;
    width 47px;
    height 18px

  #controllers span#right-arrow
    background url(images/gray/right-arrow.png) no-repeat;
    width 47px;
    height 18px

  .wp-pagenavi span.current
    color $candy !important;
    text-shadow none !important

  .wp-pagenavi a, .wp-pagenavi a:link, .wp-pagenavi a:visited
    color $light-grey !important;
    text-shadow none !important
