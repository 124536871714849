/*
 Theme Name:     Cuit Cuit Cuisine
 Theme URI:      http://cui-cuit-cuisine.com
 Description:    Cuit Cuit Cuisine Theme
 Author:         Gabriel Slama
 Author URI:     http://www.griable.com
 Template:       TheStyle
 Version:        1.0.0
*/

@import "base/index"
@import "base/fonts"
@import "base/variables"

@import "components/header"
@import "components/footer"
@import "components/nav"
@import "components/boxes"
@import "components/page"
@import "components/comments"
@import "components/pagination";
